import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/App";
import "./i18n";

import "./styles/index.scss";
import "nprogress/nprogress.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);
