import { Suspense, lazy } from "react";
import { withTranslation } from "react-i18next";

const ReduxContainer = lazy(() => import("./ReduxContainer"));

console.log("deploy test 687");

const App = () => {
  return <ReduxContainer />;
};

export default withTranslation()(App);
